<div class="object-link-field {{stylingClass}}"
     [ngClass]="{'toneDownTextColor': toneDownTextColor}">

  <div class="object-link-field__item"
       *ngFor="let item of items; index as $index"
       [ngClass]="{'hide-value': $index > 0, 'has-show-more': items.length > 1}"
  [class.has-show-more]="items.length > 1">
    <app-object-url
      class="object-link-field__objectUrl"
      [ngClass]="{'text-link': !disableLinks}"
      *ngIf="$index < 1"
      [object]="item"
      [listName]="'overview'"
      [disable]="disableLinks"
      [aClass]="stylingClass">
      <span *ngIf="$index < 1" class="object-url-content">
        @if (useMaterialSymbols) {
          <mat-icon class="material-symbols-outlined">{{ iconToMaterialSymbol(item.$$icon) }}</mat-icon>
        } @else {
          <i class="object-icon object-link-field__object-url-icon {{item.$$icon}}"></i>
        }
        <span class="object-link-field__object-url-name" [innerHTML]="item.artifact_name"></span>
      </span>
    </app-object-url>
  </div>

  <button
    mat-button
    *ngIf="items.length > 1"
    [matMenuTriggerFor]="toggleMoreFieldLink"
    (menuOpened)="showMore = true;"
    (menuClosed)="showMore = false;"
    class="object-link-field__show-more text-link">
    <span>(</span>
    <span translate="TRANS__OVERVIEW_FIELD_CONTENT__SHOW_MORE"></span>
    <span>)</span>
  </button>

  <mat-menu #toggleMoreFieldLink="matMenu"
            (click)="$event.stopPropagation()"
            class="removePaddingInMatMenu overview-show-more-menu-panel">
    <mat-list class="overview-show-more-menu">
      <ng-container *ngFor="let item of items; index as $index">
        <mat-list-item *ngIf="$index > 0"  [ngClass]="{'text-link': !disableLinks}">
          <app-object-url
            [object]="item"
            [listName]="'overview'"
            [disable]="disableLinks">
            <span class="object-url-content">
              @if (useMaterialSymbols) {
                <mat-icon class="material-symbols-outlined">{{ iconToMaterialSymbol(item.$$icon) }}</mat-icon>
              } @else {
                <i class="object-icon object-link-field__object-url-icon {{item.$$icon}}"></i>
              }
              <span>{{item.artifact_name}}</span>
            </span>
          </app-object-url>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </mat-menu>

</div>
