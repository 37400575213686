import {Component, Input, OnInit} from '@angular/core';
import {OverviewField} from '../../core/definitions/object-view';
import {OverviewFieldsService} from '../overview-fields.service';

@Component({
  selector: 'app-overview-field',
  templateUrl: './overview-field.component.html',
  styleUrls: ['./overview-field.component.scss']
})
export class OverviewFieldComponent implements OnInit {

  @Input() overviewField: OverviewField;
  @Input() viewName: string;
  @Input() disableLinks: boolean;
  @Input() toneDownTextColor: boolean;
  @Input() useMaterialSymbols: boolean = false;

  isArrayValue: boolean;
  showMore: boolean;
  expired = false;
  ready = false;

  constructor(private overviewFieldsService: OverviewFieldsService) {
  }

  ngOnInit() {
    this.initFields().then();
  }

  async initFields() {
    if (this.overviewField.items.length > 1) {
      this.isArrayValue = true;
      this.showMore = false;
    }
    for (const item of this.overviewField.items) {
      await this.setLinkItems(item);
    }
    this.ready = true;
  }

  async setLinkItems(fieldItem) {
    for (const value of fieldItem.field_values.values) {
      if (this.overviewFieldsService.valueIsRef(value)) {
        value.$$linkItems = await this.overviewFieldsService.getLinkItems([value]);
      }
    }
  }
}
