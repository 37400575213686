import {AnnotationHandler} from './annotation-handler';

export class AnnotationContainer {
  canvas;
  client;
  ctx: CanvasRenderingContext2D;
  curAnn: AnnotationHandler;
  dashboard;
  dashboardId;
  editable: boolean;
  heightIsSmallerThanContainer: boolean;
  inDialog: boolean;
  imageLoaded: boolean;
  imageId;
  imageObj;
  imageSize;
  lockHeight: boolean;
  menus: Array<any>;
  offsets: number;
  parentContainerId: string;
  quality;
  /**
   * RootNode for querying the DOM.
   * Will be the closest shadow root ancestor if there are any, or global `document`.
   */
  rootNode: DocumentFragment | Document;
}
