@if (sectionsContainer) {

  <mat-card class="overview-card">
    <app-object-overview-fields-v2
      [objectView]="sectionsContainer.objectView"
      [isEditing]="isEditing"
    >
      <span slot="title" class="overview-title">{{ 'TRANS__SECTION__OVERVIEW__TITLE' | translate }}</span>
    </app-object-overview-fields-v2>
  </mat-card>

  <app-m3-global-kpl-styles>
    <app-object-view-and-edit
      [isEditing]="isEditing"
      [sectionsContainer]="sectionsContainer"
      [templateGroupId]="contentInfo.templateGroupId"
    ></app-object-view-and-edit>
  </app-m3-global-kpl-styles>
  @if (isEditing && operationStep) {
    <app-operation-toolbar
      [operationContainer]="operationContainer"
      [operationStep]="operationStep"
    >
    </app-operation-toolbar>
  }
}
