import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {CmsQueueService} from './cms-queue.service';
import {AConst} from './a-const.enum';
import {SuperObjectModel} from './definitions/super-object-model';
import {UserData} from './definitions/user-data';
import {BaseModel} from './definitions/base-model';
import {LoggerService} from './logger.service';
import {ObjectTypeInfo} from "./definitions/object-type-info";
import {ClientConfig} from "./definitions/client-config";
import {Context} from "./definitions/context";

@Injectable({
  providedIn: 'root'
})
export class CommonsService {

  constructor(private logger: LoggerService,
              private cms: CmsApiService,
              private cmsQueue: CmsQueueService) {
    this.getObjectTypes().then(objectTypes => {
      this.objectTypes = objectTypes
    })
  }

  private objectTypes: ObjectTypeInfo[];
  private objectTypesBySuperobjectType = {};
  private objectStatusTypes = null;
  private mappedStatusTypes = {};
  private noObjectIdDisplayed = false;

  public setEditionTitle(user: UserData) {
    if (user?.edition) {
      return {
        id: user.edition,
        title: {
          Large: 'TRANS__EDITION__LARGE',
          Medium: 'TRANS__EDITION__MEDIUM',
          Small: 'TRANS__EDITION__SMALL'
        }[user.edition]
      };
    }
    return null;
  }

  public compareArrays(targetArray: any[], compArray: any[], targetProp: string,
                       compProp: string, fn: any) {
    if (!compProp) {
      compProp = targetProp;
    }
    if (targetArray && Array.isArray(targetArray)) {
      targetArray.forEach((targetItem, targetIndex) => {
        compArray.forEach((compItem) => {
          let err: string;
          if (targetItem[targetProp] &&
            compItem[compProp]) {
            if (targetItem[targetProp] ===
              compItem[compProp]) {
              fn(targetIndex);
            }
          } else {
            err = 'Items missing comparator ' +
              'property \'' + targetProp + '\'';
            if (compProp !== targetProp) {
              err += ' or \'' + compProp + '\'';
            }
            throw err;
          }
        });
      });
    } else {
      throw new Error('Target array does not exist or is not an array');
    }
  }

  // Recursive search a model for a property with name "propName".
  // This function has a weakness: Cannot have two properties with
  // the same name in the model!
  public searchModel(mod: object, propName: string, level?: number, grandParent?: object,
                     modName?: string) {
    let res = null;
    let parentName: string;
    let granny: object;
    if (!Array.isArray(mod)) {
      granny = mod;
      parentName = modName;
    } else {
      granny = grandParent;
      parentName = null;
    }
    if (mod) {
      if (!level) {
        level = 0;
      }
      if (level < 10) {
        for (const [pName, value] of Object.entries(mod)) {
          if (!pName.startsWith('$')) {
            if (pName === propName) {
              res = {
                grandParentModel: grandParent,
                parentPropName: parentName,
                parentModel: mod,
                propName: propName,
                value: value
              };
            } else {
              if (value !== null && typeof value === 'object') {
                res = this.searchModel(value, propName, level + 1, granny, pName);
              }
            }
          }
          if (res !== null) {
            break;
          }
        }
      } else {
        this.logger.warn('Too many levels in prop find! Check for circular object structures!');
      }
    } else {
      this.logger.warn('Not a valid model!');
    }
    return res;
  }

  public applyWordBreakOpportunity(input: string) {
    const re = /([\\;/_\-»])(?![^<]*>|[^<>]*<\/)/gm;
    const subst = '$1<wbr>';
    const maxLengthWithoutBreak = 25;

    if (typeof input !== 'string') {
      return input;
    }

    if (input.length > maxLengthWithoutBreak &&
      !input.match(/([\\;/_\-»\s])/gm)) {
      return input.substring(0, maxLengthWithoutBreak) + '<wbr>' +
        input.substring(maxLengthWithoutBreak);
    }

    if (input?.replace !== undefined) {
      return input.replace(re, subst);
    }
    return input;
  }

  public compareValues(value1: any, comp: string, value2: any) {
    let res = false;
    if (!comp) {
      comp = '==';
    }
    value1 = value1 === undefined ? null : value1;
    value2 = value2 === undefined ? null : value2;
    if (Array.isArray(value1) && Array.isArray(value2)) {
      // TODO: Improve array compare when necessary
      value1 = value1.length;
      value2 = value2.length;
    }
    switch (comp) {
      case '<=':
        res = value1 <= value2;
        break;
      case '>=':
        res = value1 >= value2;
        break;
      case '>':
        res = value1 > value2;
        break;
      case '<':
        res = value1 < value2;
        break;
      case '==':
        res = value1 === value2;
        break;
      case '!=':
        res = value1 !== value2;
        break;
      default:
        throw new Error('Unknown or missing comparator: \'' + comp + '\'');
    }

    return res;
  }

  public getContextIds(contexts: Context[]) {
    let res: any[];
    if (contexts) {
      res = [];
      contexts.forEach((context) => {
        if (typeof context === 'string') {
          res.push(context);
        } else {
          res.push(context.context_id);
        }
      });
    }
    return res;
  }

  public getObjectType(obj: SuperObjectModel): string {
    let objType = obj.object_type;
    const mt = obj.meta_type;
    if (mt && (mt === 'actor' || mt === 'place')) {
      objType = obj.meta_type;
    } else if (mt === 'sub_model') {
      // Could be context object type, in which case object
      // type should be obtained using context artifact id
      objType = null;
    }
    if (!objType) {

      objType = this.getObjectTypeFromObjectId(obj.artifact_id);
    }
    return objType;
  }

  public getObjectIdField(object: BaseModel) {
    let res = null;
    switch (object.object_type) {
      case 'ImageItem':
        res = 'image_id';
        break;
      case 'VideoItem':
        res = 'video_id';
        break;
      case 'AttachmentItem':
        res = 'attachment_id';
        break;
      case 'AudioItem':
        res = 'audio_id';
        break;
      case 'TemplateGroup':
        res = 'template_group_id';
        break;
      case 'Model3DItem':
        res = 'model_3d_id';
        break;
    }
    if (!res && object.artifact_id) {
      res = AConst.ARTIFACT_ID;
    }
    if (!res) {
      throw new Error('Unable to get id field for ' + object.object_type);
    }
    return res;
  }

  private async getObjectTypes(): Promise<ObjectTypeInfo[]> {
    return new Promise<ObjectTypeInfo[]>((resolve, reject) => {
      if (this.objectTypes) {
        resolve(this.objectTypes);
      } else {
        this.cmsQueue.runCmsFnWithQueue(this.cms.getClientConfig, undefined, false,
          (data: ClientConfig) => {
            this.setObjectTypes(data.OBJECT_TYPES);
            resolve(data.OBJECT_TYPES);
          },
          (e: any) => reject(e));
      }
    });
  }

  public setObjectTypes(objectTypesIn: ObjectTypeInfo[]) {
    this.objectTypes = objectTypesIn;
    for (const typePrefix in objectTypesIn) {
      if (objectTypesIn.hasOwnProperty(typePrefix)) {
        const typeData = objectTypesIn[typePrefix];
        this.objectTypesBySuperobjectType[typeData.superobject_type_id] = typeData.type;
      }
    }
  }

  public getObjectTypeFromObjectId(objectId: string): string {
    this.logger.warn(`Getting object type from object id should no longer be used: ${objectId}`);
    return this.getObjectTypeFromObjectIdWithObjectTypes(objectId, this.objectTypes);
  }

  private getObjectTypeFromObjectIdWithObjectTypes(objectId: string, objectTypes: ObjectTypeInfo[]): string {
    let typeId: string, objectType = '';
    if (!objectId) {
      if (!this.noObjectIdDisplayed) {
        this.logger.warn('No object id received');
        this.noObjectIdDisplayed = true;
      }
      return objectType;
    }
    if (objectId.length < 38) {
      if (objectId.length === 36) {
        return '';
      }
      const sepPos = objectId.indexOf('-');
      if (sepPos !== -1) {
        typeId = objectId.substring(0, sepPos);
      }
    } else {
      typeId = objectId.substring(0, objectId.length - 37);
      if (typeId.indexOf('ct_') === 0) {
        objectType = typeId;
      }
    }
    if (!typeId) {
      this.logger.warn('Type id for ' + objectId + ' not found');
      return objectType;
    }
    if (typeId.indexOf('ct_') === 0) {
      objectType = typeId;
    } else {
      const objectTypeInfo = objectTypes.find(info => info.prefix === typeId);
      if (objectTypeInfo) {
        objectType = objectTypeInfo.type;
      } else {
        this.logger.warn('No object type defined for ' + typeId);
      }
    }
    return objectType;
  }

  public uuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  public getObjectValueFromPath(object: any, path: string): any {
    if (path) {
      if (path.indexOf('.') === -1) {
        return object[path];
      }
      const pathParts = path.split('.');
      for (const partPart of pathParts) {
        if (object) {
          if (typeof object === 'object') {
            object = object[partPart];
          } else {
            this.logger.error(`Object part is not an object for ${path}, part ${partPart}`);
          }
        } else {
          this.logger.error(`Object not found for path ${path}, part ${partPart}`);
        }
      }
    }
    return object;
  }

  public setObjectValueFromPath(object: any, path: string, value: any, valueAsCopy?: boolean) {
    if (path.indexOf('.') === -1) {
      this.setObjectValue(object, path, value, valueAsCopy);
      return;
    }
    const pathParts = path.split('.');
    for (let pathIndex = 0; pathIndex < pathParts.length; pathIndex++) {
      const part = pathParts[pathIndex];
      if (pathIndex === pathParts.length - 1) {
        this.setObjectValue(object, part, value, valueAsCopy);
      } else {
        object[part] = object[part] || {};
        object = object[part];
      }
    }
  }

  public setObjectValue(object: any, key: string, value: any, valueAsCopy?: boolean) {
    if (!valueAsCopy || value === null || typeof value !== 'object') {
      object[key] = value;
    } else {
      if (Array.isArray(value)) {
        object[key] = [...value];
      } else {
        object[key] = object[key] || {};
        const subObject = object[key];
        for (const [subKey, val] of Object.entries(value)) {
          this.setObjectValue(subObject, subKey, val, true);
        }
      }
    }
  }

  public getStatusFromArtifact(art: SuperObjectModel) {
    let res = null, statusType: string;
    if (art) {
      if (art['status']) {
        statusType = art.status.status_type_id; // Object from dao
      } else if (art['status.status_type_id']) {
        statusType = art['status.status_type_id']; // Object from search
      }
      if (statusType) {
        res = this.mapStatusTypeId(statusType);
      }
    } else {
      this.logger.error('Art not defined');
    }
    return res;
  }

  private mapStatusTypeId(statusTypeId: string) {
    return this.mappedStatusTypes[statusTypeId];
  }

  public mapStatusTypeIds(objectStatusTypes: any) {
    const res = {};
    for (const key in objectStatusTypes) {
      if (objectStatusTypes.hasOwnProperty(key)) {
        const statusType = objectStatusTypes[key];
        for (const status in statusType) {// .forEach((statusTypeIdItem, status) => {
          if (statusType.hasOwnProperty(status)) {
            const statusTypeIdItem = statusType[status];
            res[statusTypeIdItem] = status;
          }
        }
      }
    }
    return res;
  }

  public async getStatusTypeIds(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.objectStatusTypes) {
        this.cmsQueue.runCmsFnWithQueue(this.cms.getObjectStatusTypes, null, false,
          (data: any) => {
            this.objectStatusTypes = data;
            this.mappedStatusTypes = this.mapStatusTypeIds(data);
            resolve(data);
          },
          (e: any) => {
            this.logger.error(`Error getting status type ids: ${e}`);
            reject(e)
          });
      } else {
        resolve(this.objectStatusTypes);
      }
    });
  }

  public sortArray(array: any[], sortOpt: any, reverse?: boolean) {
    if (!Array.isArray(sortOpt)) {
      sortOpt = [sortOpt];
    }
    array.sort((a, b) => {
      let valA = a, valB = b;
      sortOpt.forEach((name: string) => {
        valA = valA[name];
        valB = valB[name];
      });
      if (valA < valB) {
        return !reverse ? -1 : 1;
      } else if (valA > valB) {
        return !reverse ? 1 : -1;
      } else {
        return 0;
      }
    });
    return array;
  }

  public sortByProperty<T>(data: Array<T>, property: keyof T,
                           sortDesc: boolean = false, caseSensitive: boolean = false): Array<T> {
    if (!data || !Array.isArray(data)) {
      return data;
    }
    const sorted = data.sort((a, b) => {
      const aVal = caseSensitive ? a[property] : String(a[property]).toLowerCase();
      const bVal = caseSensitive ? b[property] : String(b[property]).toLowerCase();
      // eslint-disable-next-line curly
      if (aVal < bVal) return -1;
      // eslint-disable-next-line curly
      else if (aVal > bVal) return 1;
      // eslint-disable-next-line curly
      else return 0;
    });
    return sortDesc ? sorted.reverse() : sorted;
  }

  public copy(object: any, options?: any) {
    let res: any;
    options = options || {};
    if (options.ignoreEmptyArray) {
      options.ignoreUndefined = true;
    }
    if (object === null) {
      res = null;
    } else if (Array.isArray(object)) {
      if (options.ignoreEmptyArray && object.length === 0) {
        return;
      }
      res = object.map(item => this.copy(item, options));
    } else if (typeof object === 'object') {
      res = {};
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const value = this.copy(object[key], options);
          if (value !== undefined || !options.ignoreUndefined) {
            res[key] = value;
          }
        }
      }
    } else {
      res = object;
    }
    return res;
  }

  public orderArray(array: Array<any>, orderField: string, reverse?: boolean) {
    if (!array) {
      return;
    }
    let ordered = false;
    const res = array.slice();
    do {
      ordered = true;
      for (let t = 0; t < res.length - 1; t++) {
        const item1 = res[t];
        const item2 = res[t + 1];
        if (item1[orderField] > item2[orderField]) {
          res[t] = item2;
          res[t + 1] = item1;
          ordered = false;
        }
      }
    } while (!ordered);
    if (reverse) {
      res.reverse();
    }
    return res;
  }

  // Recursive equals method that hopefully replaces the old angular.equals method
  // Use the "areObjectsEqual" method if this method returns false "not equal"
  public equals(var1: any, var2: any, logUnequal?: boolean) {
    let res: boolean;
    if (var1 === null && var2 === null) {
      return true;
    }
    if (var1 === null || var2 === null) {
      return false;
    }
    if (Array.isArray(var1)) {
      if (!Array.isArray(var2)) {
        return false;
      }
      if (var1.length !== var2.length) {
        return false;
      }
      res = true;
      var1.forEach((item, index) => {
        if (!this.equals(item, var2[index])) {
          res = false;
        }
      });
      return res;
    }
    if (typeof var1 === 'object') {
      if (typeof var2 !== 'object') {
        return false;
      }
      if (Object.keys(var1).length !== Object.keys(var2).length) {
        return false;
      }
      res = true;
      for (const key in var1) {
        if (var1.hasOwnProperty(key)) {
          if (!this.equals(var1[key], var2[key])) {
            if (logUnequal) {
              this.logger.warn('Not equals ' + key);
            }
            res = false;
          }
        }
      }
      return res;
    }
    res = var1 === var2;
    if (!res && logUnequal) {
      this.logger.warn(var1 + ' != ' + var2);
    }
    return res;
  }

  // Compare two objects and return true if they are equal
  // Use the "areObjectsEqual" method instead of the "equals" method in case of false "not equal"
  public areObjectsEqual(obj1: any, obj2: any) {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const val1 = obj1[key];
        if (!obj2 || typeof obj2 !== 'object') {
          this.logger.info(`Cannot compare prop ${key} because obj2 is not an object or is null`);
          return false;
        }
        const val2 = obj2[key];
        if ((val1 && !val2) || (!val1 && val2)) {
          return false;
        }
        if (typeof val1 !== 'object' && typeof val2 !== 'object') {
          if (val1 !== val2) {
            return false;
          }
          continue;
        }
        if ((typeof val1 === 'object' && typeof val2 !== 'object') || (typeof val1 !== 'object' && typeof val2 === 'object')) {
          return false;
        }
        if (!this.areObjectsEqual(val1, val2)) {
          return false;
        }
      }
    }
    return true;
  }

  // Method for filtering out array elements based on a filter object, hopefully replaces the old angular filter "$filter('filter')"
  public filter(arr: Array<any>, filterObj: object) {
    const res = [];
    arr.forEach(item => {
      for (const filterKey in filterObj) {
        if (filterObj.hasOwnProperty(filterKey)) {
          if (this.equals(item[filterKey], filterObj[filterKey])) {
            res.push(item);
          }
        }
      }
    });
    return res;
  }

}
