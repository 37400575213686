import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {ObjectNameService} from '../../core/object-name.service';
import {CommonsService} from '../../core/commons.service';
import {LoggerService} from '../../core/logger.service';

@Component({
  selector: 'app-object-name',
  templateUrl: './object-name.component.html',
  styleUrls: ['./object-name.component.scss']
})
export class ObjectNameComponent implements OnChanges, OnDestroy {
  translate = false;

  @Input() object: any;
  @Input() objectId: string; // Use object id in cases where the object may change, else skip for static objects
  @Input() toLowerCase;
  @Input() replace;
  @Input() dontApplyWordBreak;
  @Input() stylingClass: string;

  name = '';
  gettingName = false;
  getNameCounter = 0;
  private getNameId;
  private artifactId;

  constructor(private logger: LoggerService,
              private objectNameService: ObjectNameService,
              private commons: CommonsService) {
  }

  ngOnChanges() {
    this.name = '';
    this.artifactId = this.objectId || this.object?.artifact_id;
    this.getObjName().then();
  }

  ngOnDestroy() {
    if (this.getNameId) {
      clearTimeout(this.getNameId);
    }
  }

  async getObjName(): Promise<string> {
    if ((!this.name || this.name === 'TRANS__OBJECT_NAME__MISSING') && this.object) {
      if (this.object.artifact_name) {
        this.name = this.object.artifact_name;
      } else if (this.artifactId) {
        if (!this.gettingName) {
          this.gettingName = true;
          await this.getNameFromServer();
          this.gettingName = false;
        }
      } else {
        this.name = 'TRANS__OBJECT_NAME__MISSING';
        if (this.getNameCounter++ < 100) {
          this.getNameId = setTimeout(() => {
            this.getObjName().then();
          }, 200);
        } else {
          this.logger.warn(`Gave up getting name for ${this.artifactId}`);
        }
      }
    }
    this.translate = this.name?.indexOf('TRANS_') === 0;
    return this.name;
  }

  private async getNameFromServer() {
    try {
      const result = await this.objectNameService.getObjectName({
        object: this.object,
        toLowerCase: this.toLowerCase,
        replace: this.replace
      });
      let name = result.name;
      if (name) {
        this.translate = result.translate;
        if (!this.translate && !this.dontApplyWordBreak) {
          name = this.commons.applyWordBreakOpportunity(name);
        }
        this.name = name;
      } else {
        this.logger.warn(`Could not find name for object with id ${this.artifactId}`);
      }
    } catch (e) {
      console.error('An error occurred: ' + JSON.stringify(e));
    }
  }
}
