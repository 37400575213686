import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {SettingsService} from '../../core/settings.service';

@Component({
  selector: 'app-object-icon',
  templateUrl: './object-icon.component.html',
  styleUrls: ['./object-icon.component.scss']
})
export class ObjectIconComponent implements OnInit {
  icon: string = null;

  @Input() iconClass: string;
  @Input() object;
  @Input() objectType;
  @Input() iconSize;

  constructor(private commons: CommonsService,
              private settings: SettingsService) {
  }

  ngOnInit() {
    if (!this.icon && (this.object || this.objectType)) {
      this.setIcon().then();
    }
  }

  async setIcon() {
    if (!this.objectType) {
      this.setObjectType();
    }
    await this.setIconFromObjectType();
  }

  setObjectType() {
    let objType = this.commons.getObjectType(this.object);
    const fPath = this.object[AConst.F_PATH];
    if (objType === 'concept' ||
      objType === 'conceptType' ||
      objType.startsWith('ct_')) {
      objType = 'concept';
      if (fPath) {
        const level = fPath.split('»').length - 1;
        if (level === 1) {
          objType = 'ConceptChild';
        } else if (level >= 2) {
          objType = 'ConceptGrandChild';
        }
      }
    }
    this.objectType = objType;
  }

  async setIconFromObjectType() {
    if (this.objectType) {
      this.icon = await this.settings.objectIcon(
        this.objectType.startsWith('ct_') ?
          'concept' : this.objectType,
        this.iconSize, this.object, false);
    }
  }
}
