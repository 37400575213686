<div class="overview-field {{viewName}}"
     [ngClass]="{'toneDownTextColor': toneDownTextColor}">

  <span *ngIf="!isArrayValue"
        class="overview-field__notLink-text"
        [ngClass]="{'expired': expired,
        'one-line': viewName === 'selector',
         'multiple-lines': viewName !== 'selector'}">
    <app-overview-field-item
      [fieldItem]="overviewField.items[0]"
      [viewName]="viewName"
      [disableLinks]="disableLinks"
      [last]="true"
      [toneDownTextColor]="toneDownTextColor"
      [useMaterialSymbols]="useMaterialSymbols"
    ></app-overview-field-item>
  </span>

  <ng-container *ngIf="isArrayValue">

    <span *ngFor="let item of overviewField.items; index as $index"
          class="overview-field__item"
          [ngClass]="{'hide-value': $index > 0,
          'has-show-more': overviewField.items.length > 1}">
      <app-overview-field-item
        *ngIf="$index < 1"
        [fieldItem]="item"
        [disableLinks]="disableLinks"
        [viewName]="viewName"
        [last]="true"
        [toneDownTextColor]="toneDownTextColor"
        [useMaterialSymbols]="useMaterialSymbols"
      ></app-overview-field-item>
    </span>

    <button *ngIf="overviewField.items.length > 1"
            (menuOpened)="showMore = true;"
            (menuClosed)="showMore = false;"
            [ngClass]="{'active': showMore}"
            [matMenuTriggerFor]="toggleMore"
            class="overview-field__show-more text-link"
            mat-button>
      <span>(</span>
      <span translate="TRANS__ARRAY_FIELD__SHOW_MORE"></span>
      <span>)</span>
    </button>

    <mat-menu #toggleMore="matMenu"
              (click)="$event.stopPropagation()"
              class="removePaddingInMatMenu overview-show-more-menu-panel">
      <mat-list class="overview-show-more-menu">
        <ng-container *ngFor="let item of overviewField.items; index as $index; let last = last">
          <mat-list-item *ngIf="$index > 0" class="text-link">
            <app-overview-field-item
              [fieldItem]="item"
              [viewName]="viewName"
              [disableLinks]="disableLinks"
              [toneDownTextColor]="false"
              [last]="last"
              [useMaterialSymbols]="useMaterialSymbols"
            ></app-overview-field-item>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </mat-menu>

  </ng-container>

</div>



