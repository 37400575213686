<app-m3-wrapper>
  <main>
    <app-welcome-screen-ui
      [canUseAdvancedSearch]="canUseAdvancedSearch()"
      [savedSearches]="savedSearches()"
      [objectList]="objectList()"
      [categories]="categories()"
      (listItemClick)="onListItemClick($event)"
      (searchSubmit)="onSearchSubmit($event)"
      (advancedSearchClick)="onAdvancedSearchClick()"
      [loadingObjectList]="loadingObjectList()"
      [loadingCategories]="loadingCategories()"
      [loadingSavedSearches]="loadingSavedSearches()"
      (editFocusesClick)="onEditFocusesClick()"
      (searchSuggestionClick)="onSearchSuggestionClick($event)"
      [museumInstance]="museum"
      [recentRegistrations]="recentRegistrations()"
      [loadingRecentRegistrations]="loadingRecentRegistrations()"
      (recentRegistrationClick)="onRecentRegistrationClick($event)"
      [onlyMyOwnRegistrations]="onlyMyOwnRecentRegistrations()"
      (onlyMyOwnRegistrationsChange)="onOnlyMyOwnRegistrationsChange($event)"
    >

    </app-welcome-screen-ui>

    @if (createNewOperations()?.length) {
      <button mat-fab extended class="fab-button" (click)="onCreateNewClick()">
        <mat-icon class="material-symbols-outlined">add</mat-icon>
        {{'TRANS__WELCOME_SCREEN__CREATE_NEW' | translate}}
      </button>

    }
  </main>
</app-m3-wrapper>
