import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {NotificationService} from '../../shared/notification.service';
import {AnnotationRendererService} from '../annotation-renderer.service';
import {AnnotationContainer} from '../annotation-container';
import {AnnotationMenusService} from '../annotation-menus.service';
import {AnnotationHandler} from '../annotation-handler';
import {AnnotationCursorService} from '../annotation-cursor.service';

@Component({
  selector: 'app-annotation-canvas',
  templateUrl: './annotation-canvas.component.html',
  styleUrls: ['./annotation-canvas.component.scss']
})
export class AnnotationCanvasComponent implements OnInit, OnDestroy {

  @ViewChild('canvasElement', { static: true }) canvas: ElementRef;
  @Input() curAnn: AnnotationHandler;
  @Input() parentContainerId;
  @Input() dashboardId;
  @Input() lockHeight;
  @Input() isDialog;
  @Input() edit;

  private container: AnnotationContainer;
  private mouseCoord = {x: 0, y: 0};
  private imageSize;
  private destroyed = false;

  constructor(private readonly mediaHelper: MediaHelperService,
              private readonly notificationService: NotificationService,
              private readonly annotationRendererService: AnnotationRendererService,
              private readonly annotationMenusService: AnnotationMenusService,
              private readonly annotationCursorService: AnnotationCursorService) {
  }

  ngOnInit() {
    const windowHeight = screen.height;
    const windowWidth = screen.width;

    if (windowHeight > windowWidth) {
      this.imageSize = windowHeight;
    } else {
      this.imageSize = windowWidth;
    }

    if (this.curAnn.annotationsSet) {
      this.initAnnotation();
    } else {
      this.curAnn.canvasCallback = () => {
        this.initAnnotation();
      };
    }
    this.canvas.nativeElement.addEventListener('mousedown', event => {
      this.setClickAction(event);
    });
    this.canvas.nativeElement.addEventListener('mousemove', event => {
      this.mouseMove(event);
    });
    this.canvas.nativeElement.addEventListener('mouseup', () => {
      this.mouseUp();
    });
    this.canvas.nativeElement.addEventListener('touchstart', event => {
      this.touchStart(event);
    });
    this.canvas.nativeElement.addEventListener('touchmove', event => {
      this.touchMove(event);
    });
    this.canvas.nativeElement.addEventListener('touchend', () => {
      this.touchEnd();
    });
  }

  ngOnDestroy(): void {
    this.curAnn.canvasCallback = null;
    this.canvas.nativeElement.removeEventListener('mousedown', event => {
      this.setClickAction(event);
    });
    this.canvas.nativeElement.removeEventListener('mousemove', event => {
      this.mouseMove(event);
    });
    this.canvas.nativeElement.removeEventListener('mouseup', () => {
      this.mouseUp();
    });
    this.canvas.nativeElement.removeEventListener('touchstart', event => {
      this.touchStart(event);
    });
    this.canvas.nativeElement.removeEventListener('touchmove', event => {
      this.touchMove(event);
    });
    this.canvas.nativeElement.removeEventListener('touchend', () => {
      this.touchEnd();
    });
    this.destroyed = true;
  }

  private initAnnotation() {
    this.curAnn.resetState();
    if (this.edit) {
      this.curAnn.setDrawState();
      this.curAnn.readOnly = false;
    }
    this.container = new AnnotationContainer();
    this.container.rootNode = this.canvas.nativeElement.getRootNode();
    this.container.canvas = this.canvas.nativeElement;
    this.container.curAnn = this.curAnn;
    this.container.dashboardId = this.dashboardId;
    this.container.editable = this.edit;
    this.container.imageId = this.curAnn.annotateImage.image_id;
    this.container.lockHeight = this.lockHeight;
    this.container.menus = this.annotationMenusService.getMenus(this.container);
    this.container.offsets = 2;
    this.container.parentContainerId = this.parentContainerId;
    this.container.quality = 70;
    this.container.inDialog = this.isDialog;
    this.container.imageSize = 'large';

    this.curAnn.annotationsSet = false;

    this.draw();
  }

  private draw() {
    this.annotationRendererService.drawAnnotations(this.container, {mouseCoord: this.mouseCoord});
    this.annotationCursorService.setCursor(this.container);
    this.annotationMenusService.drawMenus(this.container, this.mouseCoord);
    window.requestAnimationFrame(() => {
      if (!this.destroyed) {
        this.draw();
      }
    });
  }

  private mouseXY(event) {
    let touches, lastTouch;
    const mouseCoord = {x: 0, y: 0};
    const bodyRect = document.body.getBoundingClientRect();
    const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
    const offsetY = this.isDialog ? canvasRect.top :  canvasRect.top - bodyRect.top;
    const offsetX = canvasRect.left;
    lastTouch = event;
    touches = event.targetTouches;
    if (touches) {
      lastTouch = touches[touches.length - 1];
    }
    mouseCoord.x = lastTouch.pageX - offsetX;
    mouseCoord.y = lastTouch.pageY - offsetY;
    return mouseCoord;
  }

  private checkMove(event) {
    let ax, ay;
    const mouseCoord = this.mouseXY(event);
    ax = this.annotationRendererService.cx2ax(this.container, mouseCoord.x);
    ay = this.annotationRendererService.cy2ay(this.container, mouseCoord.y);
    if (this.curAnn.state.draw.drawing) {
      this.annotationRendererService.setAnnotationEnd(this.container, this.curAnn.selectedAnnotation, mouseCoord);
    }
    if (this.curAnn.state.draw.moving) {
      const diffX = ax - this.curAnn.selectedAnnotation.x1;
      const diffY = ay - this.curAnn.selectedAnnotation.y1;
      this.curAnn.selectedAnnotation.x1 = ax;
      this.curAnn.selectedAnnotation.y1 = ay;
      this.curAnn.selectedAnnotation.x2 += diffX;
      this.curAnn.selectedAnnotation.y2 += diffY;
    }
    return mouseCoord;
  }


  private mouseMove(event) {
    this.mouseCoord = this.checkMove(event);
  }

  private mouseUp() {
    this.checkFinish();
  }

  private touchStart(event) {
    event.preventDefault();
    this.setClickAction(event);
  }

  private touchMove(event) {
    event.preventDefault();
    this.mouseCoord = this.checkMove(event);
  }

  private touchEnd() {
    this.checkFinish();
  }

  private async newAnnotation(start) {
    const x = this.annotationRendererService.cx2ax(this.container, start.x);
    const y = this.annotationRendererService.cy2ay(this.container, start.y);
    const annotation = await this.curAnn.createAnnotation(x, y);
    this.curAnn.selectAnnotation(annotation);
    this.curAnn.state.draw.drawing = true;
  }

  private setAction(container: AnnotationContainer, mouseCoord) {
    let res = {foundAction: false};
    const state = container.curAnn.state;
    const hoverMenIdx = this.annotationMenusService.hoverMenuIndex(container, mouseCoord);
    if (hoverMenIdx >= 0) {
      const action = this.annotationMenusService.getMenus(container)[hoverMenIdx].actionState;
      container.curAnn.toggleStateAction(action);
      res.foundAction = true;
    } else if (state.action === container.curAnn.actionStates.draw && container.curAnn.selectedAnnotation) {
      res = this.annotationRendererService.setDrawAction(container, mouseCoord);
    }
    return res;
  }


  private setClickAction(event) {
    let action, hoverAnn, foundAction;
    const m = this.mouseXY(event);

    this.checkFinish();
    // First check actions to current selected annotation
    action = this.setAction(this.container, m);
    foundAction = action.foundAction;
    if (!foundAction) {
      // Next check actions to the annotation close to
      // mouse
      hoverAnn = this.annotationRendererService.getHoverAnnotation(this.container, m);
      if (hoverAnn) {
        // foundAction must be set in order to prevent
        // creation of new annotation
        foundAction = true;
        this.curAnn.selectAnnotation(hoverAnn);
      }
    }
    if (!foundAction &&
      this.curAnn.state.action === this.curAnn.actionStates.draw) {
      this.newAnnotation(m).then();
    }
  }

  private checkFinish() {
    if (this.curAnn.state.draw.drawing) {
      this.curAnn.state.draw.drawing = false;
      this.annotationRendererService.finishAnnotation(this.container, this.curAnn.selectedAnnotation);
    }
    if (this.curAnn.state.draw.moving) {
      this.curAnn.state.draw.moving = false;
      this.annotationRendererService.finishAnnotation(this.container, this.curAnn.selectedAnnotation);
    }
  }

}
