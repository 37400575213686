<div>
  <ng-content select="[slot=title]"></ng-content>
  <ul>

    @for (field of fields; track field) {
      <li>
        <div class="field-title">{{field.title}}</div>
        <div>
          <app-overview-field
            [overviewField]="field"
            [viewName]="'object-overview'"
            [useMaterialSymbols]="true"
          ></app-overview-field>
        </div>
      </li>
    }
  </ul>
</div>
