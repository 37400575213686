import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {SuperObjectModel} from '../../../core/definitions/super-object-model';
import {SearchObject} from '../../../core/definitions/search-object';
import {SearchContainer} from '../../../core/definitions/search-container';
import {PrimusRouteService} from '../../../core/primus-route.service';
import {PrimusRouterService} from '../../../core/primus-router.service';
import {CommonsService} from '../../../core/commons.service';
import {SearchPageService} from '../../search-page.service';
import {ResultViewService} from '../../result-view.service';
import {SearchResultViewType} from '../../../core/definitions/search-result-view-type.enum';
import {LoggerService} from '../../../core/logger.service';

@Component({
  selector: 'app-object-navigator',
  templateUrl: './object-navigator.component.html',
  styleUrls: ['./object-navigator.component.scss']
})
export class ObjectNavigatorComponent implements OnChanges, OnInit {

  @Input() currentObject: SuperObjectModel;
  @Input() rootObjId: string;
  @Input() rootObjType: string;
  @Input() isEditing: boolean;

  canGoPrev: boolean;
  canGoNext: boolean;
  rootItem = new SuperObjectModel();
  prevItem = {} as SearchObject;
  prevItemListName = 'overview';
  nextItem = {} as SearchObject;
  nextItemListName = 'overview';
  currentObjectId: string;
  searchMode: boolean;
  searchContainer = new SearchContainer();
  private lastUrl = '';

  constructor(private logger: LoggerService,
              private primusRoute: PrimusRouteService,
              private primusRouter: PrimusRouterService,
              private commons: CommonsService,
              private searchPageService: SearchPageService,
              private resultViewService: ResultViewService) {
  }

  ngOnChanges() {
    if (this.currentObject) {
      this.currentObjectId = this.currentObject.artifact_id;
      this.searchPageService.addSearchContainerCreationListener((searchContainer: SearchContainer) => {
        this.searchContainer = searchContainer;
        this.setCanNavigateSearchItem().then();
      });
    }
    this.searchMode = this.primusRouter.currentState().indexOf('search') !== -1;
  }

  ngOnInit() {
    if (this.primusRouter.prevSnapshotUrl?.startsWith('/new')) {
      this.lastUrl = '/admin-page';
    } else {
      this.lastUrl = this.primusRouter.prevSnapshotUrl || '/search';
    }
  }

  goBack() {
    // If coming from "create new" page and after creating an object, need to go back 3 steps  in order to get to the
    // "create new" page again, bloody nightmare with all these different cases...
    if (this.lastUrl) {
      if (this.lastUrl.startsWith('/gallery')) {
        // Return to "add new" page if previous location was gallery page
        this.lastUrl = '/admin-page';
      }
      this.primusRouter.navigate(this.lastUrl).then();
    } else {
      this.logger.warn('Unable to to back, missing source url');
    }
  }

  private async setCanNavigateSearchItem() {
    this.setRootItem();
    this.setPrevItem();
    this.setNextItem();
  }

  private setRootItem() {
    if (this.rootObjId) {
      if (this.searchContainer.targetObject?.artifact_id === this.rootObjId) {
        this.rootItem = this.searchContainer.targetObject;
        return;
      }
      const rootItem = {} as SearchObject;
      rootItem.artifact_id = this.rootObjId;
      if (!this.rootObjType) {
        rootItem.object_type = this.commons.getObjectTypeFromObjectId(this.rootObjId);
      } else {
        rootItem.object_type = this.rootObjType;
      }
      this.rootItem = rootItem;
    }
  }

  private setPrevItem() {
    this.canGoPrev = this.searchContainer.searchItemIndex > 0 || this.searchContainer.searchPage > 1;
    this.prevItemListName = this.primusRoute.params.listName;
    if (this.canGoPrev) {
      this.prevItem = this.getSearchObject(this.searchContainer, this.searchContainer.searchItemIndex - 1);
      if (this.prevItem) {
        if (this.currentObject?.object_type !== this.prevItem.object_type) {
          this.prevItemListName = 'overview';
        }
      } else {
        this.canGoPrev = false;
      }
    }
    if (!this.canGoPrev) {
      this.prevItem = <SearchObject>this.currentObject;
    }
  }

  private setNextItem() {
    this.canGoNext = this.searchContainer.searchItemIndex + 1 < this.searchContainer.searchResult.search_count ||
      this.searchContainer.searchPage < this.searchContainer.maxPage;
    this.nextItemListName = this.primusRoute.params.listName;
    if (this.canGoNext) {
      this.nextItem = this.getSearchObject(this.searchContainer, this.searchContainer.searchItemIndex + 1);
      if (this.nextItem && this.currentObject?.object_type !== this.nextItem.object_type) {
        this.nextItemListName = 'overview';
      }
    }
    if (!this.nextItem) {
      this.canGoNext = false;
      this.nextItem = <SearchObject>this.currentObject;
    }
  }

  private getSearchObject(searchContainer: SearchContainer, searchIndex: number): SearchObject {
    let res: SearchObject;
    switch (searchContainer.searchResultViewName) {
      case SearchResultViewType.LIST:
        res = searchContainer.searchResult.artifacts[searchIndex];
        break;
      case SearchResultViewType.GALLERY:
      case SearchResultViewType.THUMBNAIL:
        const columns = this.resultViewService.defaultRows[searchContainer.searchResultViewName].columns;
        const rowIndex = Math.floor(searchIndex / columns);
        const row = searchContainer.cachedScrollDataColumns[rowIndex];
        if (row) {
          res = row.columns[searchIndex % columns];
        }
        break;
      case SearchResultViewType.LIST_THUMBNAIL:
        res = searchContainer.cachedScrollDataList[searchIndex];
        break;
    }
    return res;
  }

  async goToSearch() {
    await this.primusRouter.goBackToSearch();
  }
}
