import {Component, Input, OnInit} from '@angular/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';
import {OperationService} from '../../operations/operation.service';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {OperationDef} from '../../core/definitions/operation-def';
import {PrimusRouterService} from '../../core/primus-router.service';
import {TopHeaderParentInfo} from "../../core/definitions/top-header-parent-info";

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {
  toggleMenu = false;
  firstSelectOperation: OperationDef;
  currentFirstOperation: OperationDef;
  operationContainer: OperationContainer;

  @Input() parent: TopHeaderParentInfo;
  @Input() backRouterLink: string;
  @Input() backRouterLinkLabel: string;

  constructor(public primusStateMapper: PrimusStateMapperService,
              private operationService: OperationService,
              private primusRouter: PrimusRouterService,
              private uiTools: UiToolsService) {
  }

  ngOnInit() {
    if (this.parent && !this.parent.hasSubMenus) {
      this.parent.path = 'home.primus.search';
      this.parent.target_params = {
        targetId: 'none',
        objectCount: 0
      };
    }
    this.initOperations().then();
  }

  toggleBreadcrumbsMenu() {
    this.toggleMenu = !this.toggleMenu;
    if (this.toggleMenu === true) {
      document.addEventListener('mouseup',
        this.callbackBreadcrumbsMenu.bind(this), true);

    } else {
      this.stopCallBack.bind(this);
    }
  }

  private async initOperations() {
    this.operationContainer = await this.operationService.createOperationContainer(OperationTarget.MAIN_MENU_VIEW);
    await this.operationService.setOperations(this.operationContainer);
    this.setFirstSelectOperation();
    this.setCurrentFirstSelectOperation();
  }

  private stopCallBack(): void {
    document.removeEventListener('mouseup',
      this.callbackBreadcrumbsMenu, true);
  }

  private callbackBreadcrumbsMenu(item: any) {
    const found = this.uiTools.findClassNameRecursively(
      item.target, 'dropdownIgnoreClicks');
    if (!found) {
      this.toggleMenu = false;
      this.stopCallBack.bind(this);
    }
  }

  private setFirstSelectOperation() {
    if (this.operationContainer.operations) {
      this.operationContainer.operations.forEach((operation) => {
        const menuType = operation.menu_category;
        if (menuType === 'admin') {
          this.firstSelectOperation = operation;
        }
      });
    }
  }

  private setCurrentFirstSelectOperation() {
    if (this.operationContainer.operations) {
      this.operationContainer.operations.forEach((operation) => {
        const operationStateName = operation.operation_steps[0].change_state[0].state_name;
        if (this.parent && (operationStateName === this.parent.currentState || operationStateName === this.parent.path)) {
          this.currentFirstOperation = operation;
        }
      });
    }
  }
  async goToSearch() {
    await this.primusRouter.goBackToSearch();
  }
}
