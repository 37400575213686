import {Component, Input} from '@angular/core';
import {FieldItem, FieldValue} from '../../core/definitions/object-view';
import {iconToMaterialSymbol} from '../../shared/material-icons';

@Component({
  selector: 'app-overview-field-item',
  templateUrl: './overview-field-item.component.html',
  styleUrls: ['./overview-field-item.component.scss']
})
export class OverviewFieldItemComponent {

  @Input() fieldItem: FieldItem;
  @Input() viewName: string;
  @Input() disableLinks: boolean;
  @Input() toneDownTextColor: boolean;
  @Input() last: boolean;
  @Input() useMaterialSymbols: boolean = false;

  constructor() {
  }

  valueIsRef(value: FieldValue) {
    return value.ref_id && value.ref_id.indexOf('ct_') === -1;
  }

  readonly iconToMaterialSymbol = iconToMaterialSymbol;
}
