import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MediaItem} from '../../core/definitions/media-item';
import {CommonsService} from '../../core/commons.service';
import {ObjectMediaType} from '../../core/definitions/object-media-container';
import {MediaTypeChanges} from '../media-type-changes';
import {MediaHelperService} from "../../core/media-helper.service";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-change-media-order-media-type',
  templateUrl: './change-media-order-media-type.component.html',
  styleUrls: ['./change-media-order-media-type.component.scss']
})
export class ChangeMediaOrderMediaTypeComponent implements OnInit {

  constructor(private commons: CommonsService,
              private mediaHelperService: MediaHelperService) { }

  @Input() objectMediaType: ObjectMediaType;
  @Output() mediaOrderChanges = new EventEmitter<MediaTypeChanges>();
  @ViewChild('mediaElement', {static: true}) mediaElement: ElementRef;

  mediaItems: MediaItem[];

  private static copyItem(origItem: any) {
    const newItem = {};
    for (const key in origItem) {
      if (!origItem.hasOwnProperty(key)) {
        continue;
      }
      newItem[key] = origItem[key];
    }
    return newItem;
  }

  ngOnInit(): void {
    const orderField = 'order_number';
    const mediaItems = this.objectMediaType.mediaElements.map(mediaElement =>
      ChangeMediaOrderMediaTypeComponent.copyItem(mediaElement.mediaItem));
    this.mediaItems = this.commons.sortArray(mediaItems, orderField);
    this.mediaHelperService.addThumbnailImageUrl(this.mediaItems).then(() => {
      console.log(this.mediaItems);
    });
  }

  drop(e: CdkDragDrop<MediaItem>) {
    moveItemInArray(this.mediaItems, e.previousIndex, e.currentIndex);
    this.mediaItems.forEach((mediaItem, index) => {
      mediaItem.order_number = index;
    });
    this.setChanged();
  }

  private setChanged() {
    this.mediaOrderChanges.emit({
      objectMediaType: this.objectMediaType,
      mediaItems: this.mediaItems
    });
  }

}
