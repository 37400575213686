import {SearchObject} from './search-object';
import {Facet, RangeGroup} from './search-objects';

export class SearchResult {
  artifacts: Array<SearchObject>;
  correlation_id: string;
  facets: Array<Facet>;
  facet_range_groups: Array<RangeGroup>;
  total_count: number;
  search_count: number;
  start: number;
  end: number;
  status: string;
  status_msg: string;
  status_code: any;

  // client only
  $$searchResId: number;
}
